import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';

export const Contato = () => {
    return(

        <div style={{ padding: '85px 0 0'}} id="contato-top">

            <div id='contato'>

            <Grid container
                    spacing={0} 
                    display="flex" 
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid
                        display="flex"
                        justifyContent='center' 
                        alignContent="center" 
                        alignItems="center" 
                        padding={3}
                        xs={4} sm={8} md={12}
                    >
                        <Stack >
                            <h2>Contato</h2>
                            <h4>Entre em contato conosco</h4>
                        </Stack>
                    </Grid>
                    <Grid
                        display="flex"
                        justifyContent='center' 
                        alignContent="center" 
                        alignItems="center" 
                        padding={3}
                        xs={4} sm={8} md={12}
                    >
                        <div className='contatoHeader'>Trabalhe conosco:</div>
                        <a className='contatoLink' href='mailto:recrutamento@pattrol.com.br?subject=Curriculo para oportunidade de trabalho na Pattrol (web site)'>Clique aqui para enviar o seu currículo</a>
                    </Grid>
                    <Grid
                        display="flex"
                        justifyContent='center' 
                        alignContent="center" 
                        alignItems="center" 
                        padding={3}
                        xs={4} sm={8} md={12}
                    >
                        <div className='contatoHeader'>Ouvidoria/Canal de Denúncias:</div>
                        <a className='contatoLink'  href="mailto:ouvidoria@pattrol.com.br?subject=Ouvidoria/Canal de Denúncias Pattrol (web site)">
                            Clique aqui para enviar uma denúncia
                        </a>
                    </Grid>
                    <Grid
                        display="flex"
                        justifyContent='center' 
                        alignContent="center" 
                        alignItems="center" 
                        padding={3}
                        xs={4} sm={8} md={12}
                    >
                        <div className='contatoHeader'>Nosso e-mail:</div>
                        <a className='contatoLink'  href="mailto:falecom@pattrol.com.br?subject=Contato Pattrol (web site)">
                            Clique aqui para nos enviar uma mensagem de e-mail sobre qualquer outro assunto
                        </a>
                    </Grid>
                    <Grid
                        display="flex"
                        justifyContent='center' 
                        alignContent="center" 
                        alignItems="center" 
                        padding={3}
                        xs={4} sm={8} md={12}
                    >
                        <div className='contatoHeader'>Nosso endereço:</div>
                        <a className='contatoLink'  href="https://goo.gl/maps/hvvBTpDaHWRa1fef8" target="_blank" rel="noreferrer">
                            Rua Wagner Magalhães La Badie, 02 - São Sebastião - Contagem - MG - Brasil
                        </a>
                    </Grid>
                    <Grid
                        display="flex"
                        justifyContent='center' 
                        alignContent="center" 
                        alignItems="center" 
                        padding={3}
                        xs={4} sm={8} md={12}
                    >
                        <div className='contatoHeader'>Nosso telefone:</div>
                        <a className='contatoLink'  href="tel:+553139950256">
                            +55 31 3995-0256
                        </a>
                    </Grid>
                </Grid>

            </div>

        </div>

    )
}
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

import Typography from '@mui/material/Typography';

import { MyLink } from './MyLink';


export const TopGreenBar = () => {
    return(
        <Grid container
            spacing={0} 
            sx={{ bgcolor: '#275136' }} 
            justifyContent="center" 
            alignItems="center"
            display="flex" 
            columns={{ xs: 4, sm: 12, md: 12 }}
        >
            <Grid
                display="flex"
                justifyContent='center' 
                alignContent="center" 
                alignItems="center" 
                xs={4} sm={4} md={4}
            >
                <MyLink 
                href="https://goo.gl/maps/hvvBTpDaHWRa1fef8"
                target="_blank"
                rel="noreferrer"
                >
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <LocationOnIcon sx={{ fontSize: 'medium', color:'#FF9800' }} />
                        <Typography variant="subtitle1" display="block" gutterBottom sx={{ color:'#e0e0e0' }}>
                        Rua Wagner Magalhães La Badie, 02 - São Sebastião - Contagem - MG - Brasil
                        </Typography>
                    </Stack>
                </MyLink>
            </Grid>
            <Grid 
                display="flex"
                justifyContent='center' 
                alignContent="center" 
                alignItems="center" 
                xs={4} sm={4} md={4}
            >
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} >
                <IconButton size="medium" aria-label="LinkedIn" sx={{ color:'#FFFFFF' }} 
                    onClick={() => {
                        window.open("https://www.linkedin.com/company/patrol-investiga%C3%A7%C3%B5es-geot%C3%A9cnicas-ltda/", '_blank', 'noopener,noreferrer');
                    }}
                >
                    <LinkedInIcon fontSize="inherit" />
                </IconButton>
                <IconButton size="medium" aria-label="Facebook" sx={{ color:'#FFFFFF' }} 
                    onClick={() => {
                        window.open("https://facebook.com/100063850576067", '_blank', 'noopener,noreferrer');
                    }}
                >
                    <FacebookIcon fontSize="inherit" />
                </IconButton>
                <IconButton size="medium" aria-label="Instagram" sx={{ color:'#FFFFFF' }}
                    onClick={() => {
                        window.open("https://www.instagram.com/pattrol1961/", '_blank', 'noopener,noreferrer');
                    }}
                >
                    <InstagramIcon fontSize="inherit" />
                </IconButton>
                <IconButton size="medium" aria-label="Twitter" sx={{ color:'#FFFFFF' }}
                    onClick={() => {
                        window.open("https://twitter.com/Pattrol1961", '_blank', 'noopener,noreferrer');
                    }}
                >
                    <TwitterIcon fontSize="inherit" />
                </IconButton>
                </Stack>
            </Grid>
            <Grid 
                display="flex"
                justifyContent='center' 
                alignContent="center" 
                alignItems="center" 
                xs={4} sm={4} md={4}
            >
                <MyLink href='tel:+553139950256'>
                    <Stack direction="row" spacing={1}  justifyContent="center" alignItems="center" >
                        <PhoneInTalkIcon sx={{ fontSize: 'medium', color:'#FF9800' }} />
                        <Typography variant="subtitle1" display="block" gutterBottom sx={{ color:'#e0e0e0' }}>
                            +55 31 3995-0256
                        </Typography>
                    </Stack>
                </MyLink>
            </Grid>
        </Grid>
    )
}
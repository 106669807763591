import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';

export const Valores = () => {
    return(
        <div style={{ padding: '75px 10px 20px 10px'}} id="valores-top">
            
            <Grid container
                spacing={1} 
                display="flex" 
                id="valores"
                columns={{ xs: 4, sm: 12, md: 12 }}
            >
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    xs={4} sm={12} md={12} 
                >
                    <Stack >
                        <h2>Valores</h2>
                        <h4>Nossa Cultura</h4>
                    </Stack>
                </Grid>
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignContent="center" 
                    alignItems="center" 
                    xs={4} sm={4} md={4} 
                >
                    
                    <div className='valorSeguranca'>
                        <div className='overlay' style={{height: '200px'}}>
                            <Grid container
                                spacing={0} 
                                display="flex" 
                                justifyContent="center" 
                                alignContent="center" 
                                alignItems="center" 
                                style={{height: '100%'}}
                            >
                                <Grid 
                                    display="flex" 
                                    justifyContent="center" 
                                    alignContent="center" 
                                    alignItems="center" 
                                    xs={4} sm={4} md={4} 
                                >
                                    <Typography variant="h3">
                                        Segurança
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignContent="center" 
                    alignItems="center" 
                    xs={4} sm={4} md={4} 
                >
                    <div className='valorProtagonismo'>
                        <div className='overlay' style={{height: '200px'}}>
                            <Grid container
                                spacing={0} 
                                display="flex" 
                                justifyContent="center" 
                                alignContent="center" 
                                alignItems="center" 
                                style={{height: '100%'}}
                            >
                                <Grid 
                                    display="flex" 
                                    justifyContent="center" 
                                    alignContent="center" 
                                    alignItems="center" 
                                    xs={4} sm={4} md={4} 
                                >
                                    <Typography variant="h3">
                                        Protagonismo
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignContent="center" 
                    alignItems="center"  
                    xs={4} sm={4} md={4} 
                >
                    <div className='valorTransparencia'>
                        <div className='overlay' style={{height: '200px'}}>
                            <Grid container
                                spacing={0} 
                                display="flex" 
                                justifyContent="center" 
                                alignContent="center" 
                                alignItems="center" 
                                style={{height: '100%'}}
                            >
                                <Grid 
                                    display="flex" 
                                    justifyContent="center" 
                                    alignContent="center" 
                                    alignItems="center" 
                                    xs={4} sm={4} md={4} 
                                >
                                    <Typography variant="h3">
                                        Transparência
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        
                    </div>
                </Grid>
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignContent="center" 
                    alignItems="center"  
                    xs={4} sm={4} md={4} 
                >
                    <div className='valorParcerias'>
                        <div className='overlay' style={{height: '200px'}}>
                            <Grid container
                                spacing={0} 
                                display="flex" 
                                justifyContent="center" 
                                alignContent="center" 
                                alignItems="center" 
                                style={{height: '100%'}}
                            >
                                <Grid 
                                    display="flex" 
                                    justifyContent="center" 
                                    alignContent="center" 
                                    alignItems="center" 
                                    xs={4} sm={4} md={4} 
                                >
                                    <Typography variant="h3">
                                        Parcerias que duram
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        
                    </div>
                </Grid>
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignContent="center" 
                    alignItems="center"  
                    xs={4} sm={4} md={4} 
                >
                    <div className='valorAprendizado'>
                        <div className='overlay' style={{height: '200px'}}>
                            <Grid container
                                spacing={0} 
                                display="flex" 
                                justifyContent="center" 
                                alignContent="center" 
                                alignItems="center" 
                                style={{height: '100%'}}
                            >
                                <Grid 
                                    display="flex" 
                                    justifyContent="center" 
                                    alignContent="center" 
                                    alignItems="center" 
                                    xs={4} sm={4} md={4} 
                                >
                                    <Typography variant="h3">
                                        Aprendizado contínuo
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        
                    </div>
                </Grid>
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignContent="center" 
                    alignItems="center"  
                    xs={4} sm={4} md={4} 
                >
                    <div className='valorResponsabilidade'>
                        <div className='overlay' style={{height: '200px'}}>
                            <Grid container
                                spacing={0} 
                                display="flex" 
                                justifyContent="center" 
                                alignContent="center" 
                                alignItems="center" 
                                style={{height: '100%'}}
                            >
                                <Grid 
                                    display="flex" 
                                    justifyContent="center" 
                                    alignContent="center" 
                                    alignItems="center" 
                                    xs={4} sm={4} md={4} 
                                >
                                    <Typography variant="h3">
                                        Responsabilidade
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        
                    </div>
                </Grid>


                
            </Grid>

        </div>
    )
}
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';

import labConv from '../img/lab-conv.png';
import labEsp from '../img/lab-esp.png';
import densimetro from '../img/densimetro.png';
import sondaConv from '../img/sonda-conv.png';
import sondaHidra from '../img/sonda-hidra.png';
import sondaSonica from '../img/sonda-sonica.png';
import cptu from '../img/cptu.png';
import ensaioCampo from '../img/ensaio-campo.png';
import pdi from '../img/pdi.png';

let white = '#ffffff';
let red = '#8C1B1E';
let green = '#275136';

const TileDiv = (props) => {
    return (
        <div style={{backgroundColor: props.backgroundColor, width:'100%', height:'100%', padding:props.padding}}>
            {props.children}
        </div>
    )
}

const Tile = (props) => {
    return (
        <Grid 
            display="flex" 
            justifyContent="center" 
            alignContent="center" 
            alignItems="center" 
            xs={4} sm={4} md={4}
        >
            <TileDiv backgroundColor={white} padding='20px'>
                <TileDiv backgroundColor={props.color} padding='2px'>
                    <TileDiv backgroundColor={white} padding='5px'>
                        <TileDiv backgroundColor={props.color} padding='5px'>
                            <TileDiv backgroundColor={white} padding='20px 2px 2px 2px'>
                                <Stack
                                    display="flex" 
                                    justifyContent="center" 
                                    alignContent="center" 
                                    alignItems="center" 
                                >
                                    <img src={props.src} height={150} width={150} alt="Serviço Pattrol"/>
                                    <Typography variant="h3" style={{color: props.color, paddingTop: '10px'}} gutterBottom>
                                        {props.title}
                                    </Typography>
                                    {props.children}
                               </Stack>
                            </TileDiv>
                        </TileDiv>
                    </TileDiv>
                </TileDiv>
            </TileDiv>
        </Grid>
    )
}

export const Servicos = () => {
    return(
        <div style={{ padding: '75px 0px 20px 0px'}} id="servicos-top">
            <Grid container
                spacing={0} 
                display="flex" 
                id="servicos"
                columns={{ xs: 4, sm: 12, md: 12 }}
            >
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    xs={4} sm={12} md={12} 
                >
                    <Stack >
                        <h2>Serviços</h2>
                        <h4>Nossas Ofertas</h4>
                    </Stack>
                </Grid>
                <Tile color={red} src={labConv} title='Laboratório Convencional'>
                    <div style={{color:red, padding:'15px', textAlign:'left'}}>
                        Contando com equipe altamente qualificada nosso laboratório realiza diversos ensaios de investigação geotécnica:
                        <ul style={{lineHeight:'150%'}}>
                            <li>&bull;&nbsp;Caracterização de Solos e Agregados: Massa Específica Real dos Grãos, Limite de Liquidez, Limite de Plasticidade, Limite de Contração, Granulometria por Peneiramento e Sedimentação, Teor de Umidade</li>
                            <li>&bull;&nbsp;Compactação e Índice de Suporte Califórnia (CBR)</li>
                            <li>&bull;&nbsp;Classificação MCT, HRB, USC e Resiliente</li>
                            <li>&bull;&nbsp;Permeabilidade à Carga Constante ou Variável de Solos e Agregados</li>
                            <li>&bull;&nbsp;Resistência ao Cisalhamento: Compressão Uniaxial, Cisalhamento Direto, Compressão Triaxial (UU, CU e CD)</li>
                            <li>&bull;&nbsp;Compressibilidade: Adensamento Oedométrico, HCT- Hydraulic Consolidation Test e
                        CRD – Constant Rate of Deformation</li>
                            <li>&bull;&nbsp;Triaxial de cargas repetidas – Dinâmico e Cíclico</li>
                        </ul>
                    </div>
                </Tile>
                <Tile color={green} src={labEsp} title='Laboratório Especial'>
                    <div style={{color:green, padding:'15px', textAlign:'left'}}>
                        Também contamos com aquele que é considerado o mais avançado parque tecnológico de equipamentos de investigação geotécnica da América Latina, com equipamentos servo-controlados que disponibilizam tecnologia de ponta a serviço da eficiência e acuracidade dos resultados.
                    </div>
                </Tile>
                <Tile color={red} src={densimetro} title='Densímetro Nuclear'>
                    <div style={{color:red, padding:'15px', textAlign:'left'}}>
                        Especializada em controle tecnológico em obras de construção civil, a Pattrol é pioneira na execução de ensaios de campo não destrutivos, notadamente o controle de compactação com a aplicação de Medidor Nuclear de Densidade e Umidade – Densímetro Nuclear – para aplicações em obras de pavimentação urbana e rodoviária, implantação de portos, aeroportos, barragens e infraestrutura de mineração.
                        <br />
                        <br />
                        <b>Operando desde 1997 com o licenciamento do CNEN.</b>
                    </div>
                </Tile>
                <Tile color={green} src={sondaConv} title='Sondagem Convencional'>
                    <div style={{color:green, padding:'15px', textAlign:'left'}}>
                        A Pattrol, em sua busca contínua pela excelência, investe em tecnologia de investigações geotécnicas alinhadas com as mais modernas práticas aplicadas no Mundo. Mesmo nas linhas de sondagem convencional mais tradicionais temos investimentos em tecnologia e inovação de processos visando segurança e eficiência.
                        <br />
                        <br />
                        <ul style={{lineHeight:'150%'}}>
                            <li>&bull;&nbsp;Sondagem a Trado Mecanizado</li>
                            <li>&bull;&nbsp;Sondagem a Percussão Mecanizada (SPT)</li>
                            <li>&bull;&nbsp;Sondagem a Percussão com assistência hidráulica (SPT <b><i>Safety Hammer</i></b>)</li>
                            <li>&bull;&nbsp;Coleta de Amostras Deformadas e Indeformadas</li>
                        </ul>
                    </div>
                </Tile>
                <Tile color={red} src={sondaHidra} title='Sondagem Hidráulica'>
                    <div style={{color:red, padding:'15px', textAlign:'left'}}>
                        Nossa frota de equipamentos de sondagem hidráulica conta com sondas de alguns dos melhores fabricantes do Mundo, equipadas com tecnologia de ponta que garantem o Estado da Arte em termos de segurança e eficiência na operação.
                        <br />
                        <br />
                        <ul style={{lineHeight:'150%'}}>
                            <li>&bull;&nbsp;Ferramentas de sondagem convencional, wire-line e Hollow Auger</li>
                            <li>&bull;&nbsp;Sondagem rotativa e mista com recuperação de testemunhos</li>
                            <li>&bull;&nbsp;Amostradores especiais dos tipos Shelby e Denison</li>
                            <li>&bull;&nbsp;Ensaios de Perda d'água sob Pressão - Lugeon e Lefranc</li>
                        </ul>
                    </div>
                </Tile>
                <Tile color={green} src={sondaSonica} title='Sondagem Sônica'>
                    <div style={{color:green, padding:'15px', textAlign:'left'}}>
                        Sempre em busca da fronteira tecnológica do que existe de mais moderno em termos de equipamentos e processos de investigação geotécnica a Pattrol foi responsável pela entrada em operação da primeira sonda com tecnologia dupla (sônica e hidráulica) a entrar em operação no Brasil.
                        <br />
                        <br />
                        Nossa sonda sônica é uma das principais estrelas de nossa frota e garante elevados níveis de segurança, confiabilidade e eficiência na realização de investigações geotécnicas.
                    </div>
                </Tile>
                <Tile color={red} src={cptu} title='CPTu'>
                    <div style={{color:red, padding:'15px', textAlign:'left'}}>
                        O CPTU (Cone Penetration Test) é uma das mais importantes ferramentas de prospecção geotécnica. Sua aplicação é geral, tendência comprovada pela ampliação de seu campo de utilização. Entretanto é mais recomendado nos estudos de depósitos de solos compressíveis e de baixa resistência. A precisão do equipamento possibilita estimativas realistas das propriedades do solo, justamente em condições nas quais outras técnicas de ensaio mostram-se imprecisas. Constitui-se na mais avançada ferramenta de investigação geotécnica disponível nos dias atuais.
                    </div>
                </Tile>
                <Tile color='#275136' src={ensaioCampo} title='Ensaios de campo & Instrumentação'>
                    <div style={{color:green, padding:'15px', textAlign:'left'}}>
                        Os ensaios de campo são realizados diretamente no solo, rocha ou outras estruturas (como pilhas ou barragens), sem a necessidade de escavação ou coleta de amostras. Esses ensaios podem ser realizados em diferentes profundidades, dependendo do objetivo da investigação. Entre os principais ensaios de campo estão: ensaio de penetração estática (SPT), ensaio de palheta (vane test), ensaio de cone (CPT) e ensaio de piezocone (CPTU).
                        <br />
                        <br />
                        Já a instrumentação consiste na instalação de sensores no solo ou em estruturas para medir as variações de deformação, pressão e fluxo de água. A instrumentação é frequentemente utilizada em obras de grande porte, como barragens, túneis, pontes e edifícios, para monitorar as tensões e deformações que ocorrem nas estruturas e no solo circundante. Os dados coletados pelos sensores permitem que os engenheiros monitorem a segurança das estruturas e tomem medidas para evitar problemas de estabilidade.
                        <br />
                        <br />
                        Alguns exemplos de instrumentação comumente utilizada na Geotecnia são: inclinômetros, extensômetros, piezômetros, células de carga, medidores de vazão e medidores de nível d'água. Esses equipamentos são instalados em pontos estratégicos ao longo da estrutura ou do terreno e enviam dados em tempo real para um sistema de monitoramento, que pode ser acessado pelos engenheiros a qualquer momento.
                    </div>
                </Tile>
                <Tile color='#8C1B1E' src={pdi} title='Pesquisa, Desenvolvimento & Inovação'>
                    <div style={{color:red, padding:'15px', textAlign:'left'}}>
                        A pesquisa, o desenvolvimento e a inovação de equipamentos e novas tecnologias na geotecnia são essenciais para o avanço da área e para a realização de obras seguras e eficientes, permitindo o avanço da geotecnia o avanço da geotecnia através da realização de análises mais precisas e a execução de obras cada vez mais complexas.
                        <br />
                        <br />
                        O desenvolvimento de equipamentos e novas tecnologias de investigações geotécnicas tem sido uma das principais áreas de atuação da Pattrol, sempre em sincronia com as tendências mais avançadas ao redor do Mundo.
                    </div>
                </Tile>
            </Grid>

        </div>
    )
}
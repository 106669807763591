import Grid from '@mui/material/Unstable_Grid2';

export const Header = () => {
  return (
    <header>
      <div id='page-top'></div>
      <div className='intro'>
        <div className='overlay'>

          <Grid container
              spacing={0} 
              display="flex" 
              justifyContent="center" 
              alignContent="center" 
              alignItems="center" 
              columns={{ xs: 4, sm: 8, md: 12 }}
              height="100%"
              marginTop={10}
              >
              <Grid 
                  display="flex" 
                  justifyContent="center" 
                  alignContent="center" 
                  alignItems="center" 
                  xs={4} sm={8} md={12}
              >
                <h1>Bem vindo à Pattrol</h1>
              </Grid>
              <Grid 
                display="flex" 
                justifyContent="center" 
                alignContent="center" 
                alignItems="center" 
                xs={4} sm={8} md={12}
              >
                <p>NOVOS CAMINHOS PARA A GEOTECNIA.</p>
              </Grid>
              <Grid 
                display="flex" 
                justifyContent="center" 
                alignContent="center" 
                alignItems="center" 
                xs={4} sm={8} md={12}
              >
                <a href='#manifesto' className='btn btn-custom btn-lg page-scroll'>Saiba mais</a>
              </Grid>
          </Grid>

          {/* <div className='container'>
            <div>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  Bem vindo à Pattrol
                  <span></span>
                </h1>
                <p>NOVOS CAMINHOS PARA A GEOTECNIA.</p>
                <a
                  href='#manifesto'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Saiba mais
                </a>{' '}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </header>
  )
}

import Grid from '@mui/material/Unstable_Grid2';

export const Manifesto = () => {

  const ManifestoText = (props) => {
    return (
      <div {...props}>
          <h2 >Quem somos</h2>
          <p>Ao longo de nossa jornada, já vimos e experimentamos de tudo.</p>
          <p>Entre derrapadas e bandeiradas, sempre nos reinventamos.</p>
          <p>Pois temos a certeza que, depois de cada curva, sempre vem o novo.</p>
          <p>Em 2021, celebramos 60 anos e nos sentimos com o vigor da primeira largada.</p>
          <p>Agora, são novos caminhos e horizontes. Novos desafios, novas possibilidades.</p>
          <p>E que cada curva seja bem-vinda!</p>
          <p>Afinal, somos uma equipe de alto desempenho.</p>

          <h3>Muito prazer, somos a Pattrol.</h3>
          <h3>Novos caminhos para a Geotecnia</h3>
      </div>
    )
  }

    return (
      <Grid container
        id="manifesto"  
        spacing={0} 
        display="flex" 
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
          <Grid
            display="flex"
            justifyContent='flex-end' 
            alignContent="center" 
            alignItems="center" 
            padding={3}
            xs={4} sm={4} md={6}
          >
            <div className="manifestoImg">
              
            <ManifestoText style={{textAlign:'left', paddingLeft:'20px', visibility:'hidden'}} />
            </div>
          </Grid>
          <Grid 
            display="flex" 
            justifyContent="flex-start" 
            alignContent="center" 
            alignItems="center" 
            padding={3}
            xs={4} sm={4} md={6} 
          >
            <ManifestoText style={{textAlign:'left', paddingLeft:'20px'}} />
          </Grid>
      </Grid>
    )
  }
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';

export const Proposito = () => {
    return(

        <div style={{ padding: '85px 0 0'}} id="proposito-top">
            
            <Stack >
                <h2>Missão</h2>
                <h4>Nosso Propósito</h4>
            </Stack>
            <div id='proposito'>
                {/* <div className='overlay'> */}
                        <Grid container
                            spacing={0} 
                            display="flex" 
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            <Grid 
                                display="flex" 
                                justifyContent="center" 
                                xs={4} sm={8} md={12} 
                            >
                                <Stack >
                                    <div id="propositoText">
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <h3 style={{padding:"40px" }}>Contribuir para o desenvolvimento<br />sustentável da Humanidade oferecendo<br />soluções em Engenharia Geotécnica<br />baseadas em tecnologia de ponta.</h3>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>

                                    </div>
                                </Stack>
                            </Grid>
                        </Grid>
                        <br />
                {/* </div> */}
            </div>

        </div>

    )
}